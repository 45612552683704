import React from 'react'
import Dove from '../images/dove_1f54a-fe0f.png'
import FadeInSection from './FadeInSection'

function Greeting() {
  return (
    <div className='container'>
        <FadeInSection>
            <h1 className='gap-top-normal'>Müge <br/> Bulazar</h1>
        </FadeInSection>
        <FadeInSection>
            <p className='gap-top-almostnormal'>
                Hey! I'm a freelance <span className='highlight-text'>Character Animator </span> 
                and <span className='highlight-text'>Motion Designer </span>
                from Berlin. Feel free to get in touch
                    <span className='icon'><img className='dove' src={Dove} alternative='🕊️'/></span>
            </p>
        </FadeInSection>
        
    </div>
  )
}

export default Greeting