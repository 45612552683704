import './App.css';
import Nav from './components/Nav';
import Greeting from './components/Greeting';
import Reels from './components/Reels';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Education from './components/Education';

import FadeInSection from './components/FadeInSection'

function App() {
  return (
    <div className="App">
      <div className='margin-border gap-top-tiny'>
        <div className="max-width">
          <Nav/>
          <FadeInSection>
            <Greeting/>
          </FadeInSection>
          <Reels/>
          <div className='info-container'>
            <Experience/>
            <Education/>
          </div>
        </div>
      </div>
      <FadeInSection>
        <Contact/>
      </FadeInSection>
      <div className='credits'> bæ 2023</div>
    </div>
  );
}

export default App;
