import React from 'react'
import TableRow from './TableRow.js'
import FadeInSection from './FadeInSection.js'

function Education() {

    const tableData = [
        {
            name: 'The Animation Workshop',
            tagline: '3D Character Animation course',
            url: 'https://animationworkshop.via.dk/',
            year: '2022',
            id: '2'
        },
        {
            name: 'Filmuniversität Babelsberg',
            tagline: 'BfA Animation',
            url: 'https://www.filmuniversitaet.de/',
            year: '2014-2019',
            id: '1'
        },
        {
            name: 'Animation Collaborative',
            tagline: '3D Masterclass with Michal Makarewicz',
            url: 'https://animc.com/',
            year: '2018',
            id: '3'
        },
    ]
  return (
    <div className='info-item gap-top-normal'>
        <FadeInSection>
            <h3>Education</h3>
        </FadeInSection>
        <div className='gap-top-small'></div>
        <div className='tableContainer'>
        {
            tableData.map((item) => (
                <TableRow item={item} key={item.id}/>
            ))
        }
        </div>
        
    </div>
  )
}

export default Education