import React from 'react'
import FadeInSection from './FadeInSection'

function TableRow({item}) {
  return (
    <div>
        <FadeInSection>

          <div className='table-cell'>
            <div className='table-row'>
                <div><a className='table-link' href={item?.url}>{item.name}</a></div>
                <div>{item.year}</div>
            </div>
            <div className='tagline'>{item.tagline}</div>
          </div>
          
        </FadeInSection>
    </div>
  )
}

export default TableRow