import React from 'react'
import avatar from '../images/muegeAvatar.jpg';

function Avatar() {
  return (
    <div className='avatar-container'>
        <img className='avatar' src={avatar} alt="Avatar"/>
    </div>
    
  )
}

export default Avatar