import React from 'react'
import TableRow from './TableRow.js'
import FadeInSection from './FadeInSection.js'

function Experience() {

    const tableData = [
        {
            name: 'Aixsponza',
            tagline: '3D Character Animation & Rigging',
            url: 'https://aixsponza.com/',
            year: '2023',
            id: '4'
        },
        {
            name: 'RBB',
            tagline: 'Motion Design',
            url: "https://www.rbb24.de/",
            year: '2021-today',
            id: '3'
        },
        {
            name: 'Gamomat',
            tagline: 'Spine Animation & Rigging',
            url: 'https://gamomat.com/',
            year: '2020-2022',
            id: '1'
        },
        {
            name: 'Babygiant',
            tagline: '3D Animation & Matchmove',
            url: 'https://www.babygiant.studio/',
            year: '2019',
            id: '2'
        },
    ]
  return (
    <div className='info-item gap-top-normal'>
        <FadeInSection>
            <h3>Experience</h3>
        </FadeInSection>
        <div className='gap-top-small'></div>
        <div className='tableContainer'>
        {
            tableData.map((item) => (
                <TableRow item={item} key={item.id}/>
            ))
        }
        </div>
        
    </div>
  )
}

export default Experience