import React from 'react'
import { FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Avatar from './Avatar';

function Contact() {
  return (
    <footer className='container gap-top-normal '>
        <div className='contact-details-container'>
          <div className='contact-details-item'>
            <Avatar/>
          </div>
          <div className='contact-details-item flexbox-container'>
            <h1 className='h1-small'>Müge <br/>Bulazar</h1>
            <h2>Character Animator &<br></br>Motion Designer</h2>
            <ul>
                <li className='contact-details-link-icon'><MdEmail /><a className="contact-details-link" href="mailto:muege.b@gmail.com">muege.b@gmail.com</a></li>
                <li className='contact-details-link-icon'><FaLinkedin /><a className="contact-details-link" href="https://www.linkedin.com/in/muegebulazar/">/muegebulazar</a></li>
            </ul>
          </div>
        </div>
        
        
    </footer>
  )
}

export default Contact