import React from 'react'
import MotionThumbnail from '../images/2D_Reel_TN2.png';
import CharacterThumbnail from '../images/3D_Reel_TN_ohneRand.png';
import VimeoPlayer from './VimeoPlayer';


function Reels() {
  
  const videoData = [
    {id:1, title: 'Character', thumbnail: CharacterThumbnail, videoId: "866241560", disclaimer: true},
    {id:2, title: 'Motion', thumbnail: MotionThumbnail, videoId: "896149428", disclaimer: false}
  ]
  
  return (
    <div className='reels gap-top-normal'>
        <VimeoPlayer key={videoData[0].id} thumbnail={videoData[0].thumbnail} title={videoData[0].title} videoId={videoData[0].videoId} disclaimer={videoData[0].disclaimer}></VimeoPlayer> 
        <VimeoPlayer key={videoData[1].id} thumbnail={videoData[1].thumbnail} title={videoData[1].title} videoId={videoData[1].videoId} disclaimer={videoData[1].disclaimer}></VimeoPlayer> 
    </div>
  )
}

export default Reels