import React, { useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import FadeInSection from './FadeInSection';
// import Vimeo from '@u-wave/react-vimeo';

class VimeoPlayer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            playing: false,
            fadeOut: false,
            disclaimer: props.disclaimer,
        };

        this.url = 'https://vimeo.com/' + props.videoId

        this.handlePause = this.handlePause.bind(this);
        this.handlePlayerPause = this.handlePlayerPause.bind(this);
        this.handlePlayerPlay = this.handlePlayerPlay.bind(this);

        this.imageStyle = {
            height:'100%' ,
            objectFit: 'cover'
        }

        // Create a ref to store the DOM element
        this.thumbnailRef = React.createRef();
    }

    handlePause(event) {
        this.setState({
            playing: !event.target.checked,
        });

        const { playing  } = this.state;
        console.log(`playing:${playing}`)

        if(playing){
            this.setState({playing:false})
        } else {
            this.setState({playing:true})
        };

        // set vimeo-container-thumbnail opacity to zero
        // get element
        console.log(this.thumbnailRef);
        this.setState({ fadedOut: true })

    }
    
    handlePlayerPause() {
        this.setState({ playing: false });

    }
    
    handlePlayerPlay() {
        this.setState({ playing: true });
    }

    render() {

        const { playing, disclaimer  } = this.state;
        const fadeClass = this.state.fadedOut ? 'fade-out' : '';

        return (
            // <FadeInSection>
                <div className='vimeo-container'>
                    <div className="player-wrapper">
                        <div className={`vimeo-title-container fade-element ${fadeClass}`}>{this.props.title}</div>
                        <img className={`vimeo-container-thumbnail fade-element ${fadeClass}`} onClick={this.handlePause} ref={this.thumbnailRef} src={this.props.thumbnail} alt="thumbnail" style={this.imageStyle}/>
                        <ReactPlayer
                            url={this.url}
                            playing={playing}
                            controls
                            pip={false}
                            onPause={this.handlePlayerPause}
                            onPlay={this.handlePlayerPlay}
                            width='100%'
                            height='100%'
                            className="react-player"
                            loop
                        />
                        {disclaimer && (
                            <p className={`password-disclaimer fade-element ${fadeClass}`}>🔒Non public released material. Please request a password <a href="mailto:muege.b@gmail.com">here</a></p>
                        )}
                        
                    </div>
                </div> 
            // </FadeInSection>
        );
    }
}

export default VimeoPlayer