import React from 'react'
import { RxHamburgerMenu } from "react-icons/rx";


function Nav() {

    const hamburgerStyle = {
    }

  return (
    <nav>
        <RxHamburgerMenu style={hamburgerStyle}/>
    </nav>
  )
}

export default Nav